import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';

const AtlasBreadcrumb = (props) => {
  const { items, onSelectFeature, onBackToCountry } = props;
  const classes = useStyles();

  if (items.length === 0) return null;
  const onSelect = (item) => {
    const newItems = items.reduce((acc, curr) => {
      if (curr.level > item.level) {
        return acc;
      } else {
        acc.push(curr);
        return acc;
      }
    }, []);
    onSelectFeature(item.selectedFeature);
  };

  return (
    <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
      <div
        className={classes.breadcrumbBtns}
        color="inherit"
        href="/"
        onClick={() => onBackToCountry()}
      >
        ← COUNTRY
      </div>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={classes.breadcrumbBtns}
            color="inherit"
            href="/"
            onClick={() => onSelect(item)}
          >
            {item.title}
          </div>
        );
      })}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    position: 'absolute',
    zIndex: 2,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingTop: 10,
    marginBottom: 5,
  },
  breadcrumbBtns: {
    cursor: 'pointer',
    padding: '3px 6px',
    border: '1px solid',
    borderRadius: '4px',
    '&:hover': {
      color: '#1A202C',
    },
  },
}));

export default AtlasBreadcrumb;
