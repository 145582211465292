import React from 'react';
import Chart from 'react-apexcharts';
import Skeleton from '@material-ui/lab/Skeleton';
import TheChartLabels from './TheChartLabels.js';

const Title = (props) => {
  const { title } = props;

  if (!title) return null;

  return <h4 style={{ textDecoration: 'underline' }}>{title}</h4>;
};

const TheChart = (props) => {
  const { data, isLoading, type } = props;
  if (isLoading)
    return (
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            transform: 'rotate(180deg)',
          }}
        >
          {Array.from(Array(5)).map((e, index) => {
            return (
              <React.Fragment key={index}>
                <div style={{ paddingRight: 5 }} />
                <Skeleton
                  variant="rect"
                  width={50}
                  height={120 / (index + 1)}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  const options = {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#aaaaaa'],
    },
    dropShadow: {
      enabled: false,
    },
    style: {
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'regular',
      colors: ['#333'],
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };
  if (!data[0]) return null;
  const minMax = [1, data[0].count];

  let state;
  let chartType;

  switch (type) {
    case 'donut':
    case 'donut_%':
      chartType = 'donut';
      const total = data.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0);
      state = data.reduce(
        (accumulator, currentValue) => {
          return (accumulator = {
            series: [
              ...accumulator.series,
              Math.round((currentValue.count / total) * 100),
            ],
            options: {
              ...options,
              colors: [...accumulator.options.colors, currentValue.color],
              labels: [...accumulator.options.labels, currentValue.value],
              dataLabels: {
                enabled: true,
                background: {
                  enabled: false,
                },
                formatter: function (val) {
                  return Math.round(val) + '%';
                },
              },
            },
          });
        },
        {
          series: [],
          options: {
            colors: [],
            labels: [],
          },
        }
      );
      break;
    case 'histogram':
      chartType = 'bar';
      state = data.reduce(
        (accumulator, currentValue) => {
          return {
            series: [
              {
                data: [...accumulator.series[0].data, currentValue.count],
              },
            ],
            options: {
              xaxis: {
                categories: [
                  ...accumulator.options.xaxis.categories,
                  currentValue.value,
                ],
              },
              yaxis: [
                {
                  min: minMax[0],
                  max: minMax[1],
                  logarithmic: true,
                },
              ],

              dataLabels: {
                enabled: false,
              },
            },
          };
        },
        {
          series: [
            {
              data: [],
            },
          ],
          options: {
            ...options,
            colors: [],
            xaxis: {
              categories: [],
            },
          },
        }
      );
      break;
    case 'horizontal':
      {
        chartType = 'bar';
        const total = data.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.count;
        }, 0);

        state = data.reduce(
          (accumulator, currentValue) => {
            return {
              series: [
                {
                  data: [
                    ...accumulator.series[0].data,
                    Math.round((currentValue.count / total) * 100),
                  ],
                },
              ],
              options: {
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                xaxis: {
                  categories: [
                    ...accumulator.options.xaxis.categories,
                    currentValue.value,
                  ],
                },
                // yaxis: [
                //   {
                //     min: minMax[0],
                //     max: minMax[1],
                //     logarithmic: true,
                //   },
                // ],

                dataLabels: {
                  enabled: true,
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            };
          },
          {
            series: [
              {
                data: [],
              },
            ],
            options: {
              ...options,
              colors: [],
              xaxis: {
                categories: [],
              },
            },
          }
        );
      }
      break;
    default:
      break;
  }

  return (
    <Chart
      options={state.options}
      series={state.series}
      type={chartType}
      height={170}
    />
  );
};

const TheSidebarChart = (props) => {
  const { data, isLoading, type, title, displayLabels } = props;

  return (
    <div>
      <Title title={title} />
      <TheChartLabels
        displayLabels={displayLabels}
        data={data}
        isLoading={isLoading}
      />
      <TheChart data={data} isLoading={isLoading} type={type} />
    </div>
  );
};
const MemoizedTheSidebarChart = React.memo(TheSidebarChart);
export default MemoizedTheSidebarChart;
