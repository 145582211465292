import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Skeleton from '@material-ui/lab/Skeleton';
import TheTableBody from './TheTableBody';
import TheTableHead from './TheTableHead';

import { TableContext } from '../../providers/provideTableData.js';

const useStyles = makeStyles({
  table: {
    maxHeight: '100%',
    maxWidth: '100%',
    color: '#2D3748',
  },
});

const DenseTable = (props) => {
  const [tableData, measure, highlightedFeatureName, onHover] = useContext(
    TableContext
  );
  const classes = useStyles();

  const styles = {
    display: 'grid',
    gap: '10px',
    height: '100%',
    gridTemplateRows: 'auto '.repeat(10),
    gridAutoFlow: 'column',
  };
  if (tableData.isFetching) {
    return (
      <div style={styles}>
        {Array.from(Array(60)).map((d, index) => {
          return <Skeleton variant="rect" width={210} height={20} />;
        })}
      </div>
    );
  }

  if (!tableData.data) return null;

  if (tableData.data.length === 0)
    return (
      <div
        style={{
          fontSize: 25,
          color: '#718096',
          paddingTop: 50,
          textAlign: 'center',
        }}
      >
        No Data
      </div>
    );

  const populateNullValuesWithZero = (values, numOfValues) => {
    if (values.length === numOfValues) return values;
    values.sort((a, b) => a.value - b.value);

    let newValues = [];
    let counter = 0;

    while (newValues.length < numOfValues) {
      if (counter < values.length) {
        newValues.push(values[counter]);
      } else {
        newValues.push({ value: counter + 1, count: 0 });
      }

      counter++;
    }

    return newValues;
  };

  const generateColumnTotalsLine = (rows) => {
    let totals = ['Totals'];
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      for (let j = 1; j < element.length; j++) {
        if (i === 0) {
          totals[j] = element[j];
        } else {
          totals[j] = {
            value: element[j].value,
            count: totals[j].count + element[j].count,
          };
        }
      }
    }
    return totals;
  };

  const totalColumnsCount = Math.max(
    ...tableData.data.map((d) => {
      return d.values.length;
    })
  );

  const rows = tableData.data.reduce((acc, cur) => {
    const total = cur.values.reduce((acc, cur) => {
      acc += cur.count;
      return acc;
    }, 0);

    cur.values.sort((a, b) => a.value - b.value);

    const row = populateNullValuesWithZero(
      cur.values,
      totalColumnsCount
    ).concat({
      value: 'total',
      count: total,
    });

    acc.push([cur.name, ...row]);
    return acc;
  }, []);

  const totalsLine = generateColumnTotalsLine(rows);
  const rowsWithTotalsLine = rows.concat([totalsLine]);

  return (
    <TableContainer className={classes.table}>
      <div style={{ padding: '19px 0px 3px 16px', fontWeight: '500' }}>
        <h4
          style={{
            margin: '0 0 0 -5px',
            paddingLeft: 5,
            minWidth: '40%',
            borderBottom: '1px solid black',
            display: 'inline-block',
          }}
        >
          {measure.label}:
        </h4>
      </div>
      <Table
        style={{ color: '#2D3748' }}
        size="small"
        aria-label="a dense table"
      >
        <TheTableHead data={tableData.data} measure={measure} />
        <TheTableBody
          rows={rowsWithTotalsLine}
          onHover={onHover}
          highlightedFeatureName={highlightedFeatureName}
        />
      </Table>
    </TableContainer>
  );
};

export default DenseTable;
