import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TheTableRow = (props) => {
  const { row, index, onHover, isHighlighted } = props;

  const StyledTableRow = isHighlighted
    ? withStyles(() => ({
        root: {
          cursor: 'pointer',
          backgroundColor: '#E2E8F0',
        },
      }))(TableRow)
    : withStyles((theme) => ({
        root: {
          cursor: 'pointer',
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:last-child': {
            cursor: 'default',
            border: '0px solid #718096',
            borderTopWidth: '2px',
          },
        },
      }))(TableRow);

  const RigthAlignedTableCell = withStyles(() => ({
    root: {
      textAlignLast: 'right',
    },
  }))(TableCell);

  return (
    <StyledTableRow onMouseEnter={() => onHover(row, index)} key={index}>
      {row.map((cell, index) => {
        if (index === 0) {
          return (
            <TableCell key={index} component="th" scope="row">
              {cell}
            </TableCell>
          );
        } else {
          return (
            <RigthAlignedTableCell key={index} component="th" scope="row">
              {cell.count.toLocaleString()}
            </RigthAlignedTableCell>
          );
        }
      })}
    </StyledTableRow>
  );
};

export default TheTableRow;
