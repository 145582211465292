import * as d3 from 'd3';

export default (data, measure, CHOROPLETH_COLORS, CATEGORICAL_COLORS) => {
  let idProp = 'id';
  const mapData = {}; //{id: value}
  const allVals = []; //[ value, value, ...]
  const ids = [];

  Object.values(data).forEach((d) => {
    ids.push(d.id); //in case we want to have a data-driven fill-patern
    mapData[d.id] = d.value;
    allVals.push(d.value);
  });

  const fillOpacity = [
    'case',
    ['==', ['get', ['to-string', ['get', idProp]], ['literal', mapData]], null],
    0.1,
    0.6,
  ];

  if (measure.type !== 'list') {
    const extent = d3.extent(allVals);
    const scale = d3
      .scaleQuantize()
      .domain(extent)
      .range(CHOROPLETH_COLORS)
      .nice();

    let choroplethBreaks = scale.thresholds();

    const fillStyle = [
      'case',
      [
        '==',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        null,
      ],
      '#ccc',
      [
        '<',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        choroplethBreaks[0],
      ],
      CHOROPLETH_COLORS[0],
      [
        '<',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        choroplethBreaks[1],
      ],
      CHOROPLETH_COLORS[1],
      [
        '<',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        choroplethBreaks[2],
      ],
      CHOROPLETH_COLORS[2],
      [
        '<',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        choroplethBreaks[3],
      ],
      CHOROPLETH_COLORS[3],
      CHOROPLETH_COLORS[4],
    ];

    return { 'fill-color': fillStyle, 'fill-opacity': fillOpacity };
  } else {
    let fillStyle = [
      'case',
      [
        '==',
        ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
        null,
      ],
      '#ccc',
    ];

    measure.values.forEach((value, i) => {
      if (i >= CATEGORICAL_COLORS.length) return; // for now just skip an excessive number of categories
      fillStyle.push(
        [
          '==',
          [
            'round',
            ['get', ['to-string', ['get', idProp]], ['literal', mapData]],
          ],
          i + 1,
        ],
        CATEGORICAL_COLORS[i]
      );
    });
    fillStyle.push('#ccc');

    return { 'fill-color': fillStyle, 'fill-opacity': fillOpacity };
  }
};
