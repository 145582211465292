import React from 'react';
import TheChartLabels from './theChart/TheChartLabels.js';
import { useChartData } from './../providers/provideChartData';

export default (props) => {
  const { className, title } = props;

  const [_, { data, isFetching: isLoading }] = useChartData();

  return (
    <div className={className}>
      <div
        style={{
          fontSize: '14px',
          margin: '15px 0px',
          color: '#1A202C',
        }}
      >
        {title}
      </div>
      <TheChartLabels data={data} isLoading={isLoading} labelsMaxWidth={150} />
    </div>
  );
};
