export default (breadcrumbItems, feature) => {
  let items = [...breadcrumbItems];

  const itemIndex = items.findIndex(
    (item) => item.title === feature.properties.name
  );

  if (itemIndex !== -1) {
    const newItems = items.reduce((acc, curr, index) => {
      if (index <= itemIndex) {
        acc.push(curr);
      }
      return acc;
    }, []);
    items = newItems;
  } else {
    items.push({
      level: items.length,
      title: feature.properties.name,
      selectedFeature: feature,
      featureLayer: feature.layer.id,
    });
  }

  return items;
};
