import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TheTableRow from './TheTableRow';

const TheTableBody = (props) => {
  const { rows, highlightedFeatureName, onHover } = props;

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isRowHighlighted = highlightedFeatureName
          ? row[0] === highlightedFeatureName
          : false;
        return (
          <TheTableRow
            key={index}
            isHighlighted={isRowHighlighted}
            row={row}
            index={index}
            onHover={(row, index) => onHover(row, index)}
          />
        );
      })}
    </TableBody>
  );
};

const MemoizedTheTableBody = React.memo(
  TheTableBody,
  (prevProps, nextProps) => {
    if (nextProps.highlightedFeatureName !== prevProps.highlightedFeatureName) {
      // console.log('render 1');
      return false;
    }
    if (nextProps.rows.length !== prevProps.rows.length) {
      // console.log('render 3');
      return false;
    }
    return true;
  }
);

export default MemoizedTheTableBody;
