import axios from 'axios';
import { API } from './../config';

const formatChartData = (
  response,
  measure,
  CATEGORICAL_COLORS,
  CHOROPLETH_COLORS
) => {
  const data = response.data.map((d, index) => {
    return measure.type === 'list'
      ? {
          ...d,
          value: measure.values[d.value - 1],
          color: CATEGORICAL_COLORS[d.value - 1],
        }
      : index === response.data.length - 1
      ? { ...d, value: `${d.value}+`, color: CHOROPLETH_COLORS[index] }
      : {
          ...d,
          value: `${d.value} - ${response.data[index + 1].value}`,
          color: CHOROPLETH_COLORS[index],
        };
  });
  return data;
};

export default async (
  measure,
  filters,
  CATEGORICAL_COLORS,
  CHOROPLETH_COLORS
) => {
  try {
    const response = await axios.post(`${API}chart/`, {
      field: measure.key,
      filter: filters,
    });

    const data = await formatChartData(
      response,
      measure,
      CATEGORICAL_COLORS,
      CHOROPLETH_COLORS
    );
    const type = measure.type === 'list' ? 'donut' : 'histogram';

    return { data, type };
  } catch (error) {
    throw error;
  }
};
