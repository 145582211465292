import React from 'react';
import { Popup } from 'react-map-gl';

import TheChart from './theChart/TheChart.js';
import { useChartData } from '../providers/provideChartData';

export default (props) => {
  const { longitude, latitude, tile, info, description, displayChart } = props;

  const [chartData, _] = useChartData();

  if (displayChart) {
    return (
      <Popup longitude={longitude} latitude={latitude} closeButton={false}>
        <div
          style={{
            maxWidth: '220px',
            margin: '5px 6px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ color: '#1A202C', margin: '12px 0px' }}>{tile}</div>
          <div style={{ width: 150 }}>
            <TheChart
              displayLabels={false}
              data={chartData.data}
              type="donut_%"
              isLoading={chartData.isFetching}
            />
          </div>
        </div>
      </Popup>
    );
  } else {
    return (
      <Popup longitude={longitude} latitude={latitude} closeButton={false}>
        <div
          style={{
            maxWidth: '220px',
            margin: '5px 6px',
          }}
        >
          <div style={{ color: '#2D3748', margin: '12px 0px' }}>
            {description}
          </div>
          <div style={{ fontSize: '18px', color: '#1A202C' }}>{info}</div>
        </div>
      </Popup>
    );
  }
};
