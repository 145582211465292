import * as d3 from 'd3';
import { CHOROPLETH_COLORS, CATEGORICAL_COLORS } from './../config';

const BASE_GRID_STYLE = {
  'circle-radius': ['^', ['get', 'count'], 3 / 8],
  'circle-opacity': 0.75,
  'circle-stroke-color': '#999',
  'circle-stroke-width': 1,
};

export default (gridData, measure) => {
  const allVals = gridData.features.map((f) => f.properties.value);
  const extent = d3.extent(allVals);
  const scale = d3
    .scaleQuantize()
    .domain(extent)
    .range(CHOROPLETH_COLORS)
    .nice();

  const breaks = scale.thresholds();

  if (measure.type !== 'list') {
    const fill = [
      'case',
      ['==', ['get', 'value'], null],
      '#ccc',
      ['<', ['get', 'value'], breaks[0]],
      CHOROPLETH_COLORS[0],
      ['<', ['get', 'value'], breaks[1]],
      CHOROPLETH_COLORS[1],
      ['<', ['get', 'value'], breaks[2]],
      CHOROPLETH_COLORS[2],
      ['<', ['get', 'value'], breaks[3]],
      CHOROPLETH_COLORS[3],
      CHOROPLETH_COLORS[4],
    ];
    return { ...BASE_GRID_STYLE, 'circle-color': fill };
  } else {
    let fill = ['case', ['==', ['get', 'value'], null], '#ccc'];
    measure.values.forEach((value, index) => {
      fill.push(['==', ['get', 'value'], index + 1], CATEGORICAL_COLORS[index]);
    });
    fill.push('#ccc');
    return { ...BASE_GRID_STYLE, 'circle-color': fill };
  }
};
