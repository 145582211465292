import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function CheckboxesGroup(props) {
  const { name, labels, onChange } = props;

  const classes = useStyles();

  if (!labels) return null;
  const handleChange = (event) => {
    const list = labels.map((item) => {
      if (item.name === event.target.name) {
        return { ...item, active: event.target.checked };
      } else {
        return { ...item };
      }
    });
    onChange({ name, list });
  };

  const renderLabels = (labels) => {
    return labels.map((label, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={label.active}
              onChange={handleChange}
              name={label.name}
              color="primary"
            />
          }
          label={label.name}
        />
      );
    });
  };
  return (
    <div className={classes.root}>
      <h4 style={{ marginBottom: 4, marginTop: 10 }}>{name}</h4>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>{renderLabels(labels)}</FormGroup>
      </FormControl>
    </div>
  );
}
