import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default (props) => {
  const { data, isLoading, displayLabels, labelsMaxWidth } = props;
  if (displayLabels === false) return null;
  if (isLoading)
    return (
      <div>
        <div style={{ width: '100%', paddingBottom: 10 }}>
          {Array.from(Array(5)).map((d, index) => {
            return (
              <React.Fragment key={index}>
                <div style={{ paddingTop: 5 }} />
                <Skeleton variant="rect" height={20} width={'60%'} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  const [hoveredLabelIndex, setHoveredLabelIndex] = useState();

  const colorIndicatorStyle = {
    display: 'flex',
    width: 14,
    minWidth: 14,
    height: 14,
    marginRight: 10,
    border: '1px solid #A0AEC0',
    borderRadius: '1px',
  };

  const truncateStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: labelsMaxWidth ? labelsMaxWidth : 220,
    overflow: 'hidden',
  };

  const hoverStyle = {
    position: 'absolute',
    bottom: '20px',
    left: '-4px',
    borderRadius: '5px',
    backgroundColor: '#E2E8F0',
    width: 'max-content',
    padding: '3px 5px',
    fontSize: '14px',
    maxWidth: '165px',
    pointerEvents: 'none',
  };

  const handleOnHover = (index) => {
    setHoveredLabelIndex(index);
  };

  const renderFullLabel = (label, index) => {
    if (index !== hoveredLabelIndex) return null;

    return <div style={{ ...hoverStyle }}>{label}</div>;
  };

  const labels = data.map((d, index) => {
    return (
      <div
        key={index}
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <div style={{ ...colorIndicatorStyle, backgroundColor: d.color }} />
        <div
          style={{ position: 'relative', cursor: 'pointer' }}
          onMouseEnter={() => handleOnHover(index)}
          onMouseLeave={() => handleOnHover(null)}
        >
          <div style={{ fontSize: '14px', ...truncateStyle }}>{d.value}</div>
          {renderFullLabel(d.value, index)}
        </div>
      </div>
    );
  });
  return <div>{labels}</div>;
};
