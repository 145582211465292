import * as d3 from 'd3';

export default (feature, zoomLevel, geographicData, measure) => {
  if (!feature)
    return {
      feature: null,
      info: null,
    };

  const d =
    zoomLevel.name === 'grid'
      ? feature.properties
      : geographicData.data[feature.properties.id];
  let formatted;
  if (d === undefined) formatted = 'N/A';
  else {
    if (measure.type === 'list') {
      formatted = measure.values[Math.round(d.value) - 1];
    } else {
      formatted = d3.format('.2~r')(d.value);
    }
  }
  return {
    feature: feature,
    info: formatted,
  };
};
