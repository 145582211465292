import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default (props) => {
  const { isAtlasLoading } = props;
  return isAtlasLoading ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(255,255,255, 0.3)',
        zIndex: 10,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div></div>
  );
};
