import React, { useState, useContext, createContext, useEffect } from 'react';
import { fetchChartData, generateChartFilters } from '../lib/helpers/index';

import { CHOROPLETH_COLORS, CATEGORICAL_COLORS } from '../lib/config.js';

const ChartDataContext = createContext();

export function ProvideChartData({
  children,
  measure,
  dataFilters,
  dataHoverFilter,
}) {
  const { chartData, summaryChartData } = useProvideChartData(
    measure,
    dataFilters,
    dataHoverFilter
  );

  return (
    <ChartDataContext.Provider value={[chartData, summaryChartData]}>
      {children}
    </ChartDataContext.Provider>
  );
}

export const useChartData = () => {
  return useContext(ChartDataContext);
};

function useProvideChartData(measure, dataFilters, dataHoverFilter) {
  const [chartData, setChartData] = useState({ data: null, isFetching: true });

  useEffect(() => {
    let didCancel = false;
    if (!measure.key) return;
    const fetchData = async () => {
      try {
        setChartData({ data: null, isFetching: true });

        const { data, type } = await fetchChartData(
          { type: measure.type, values: measure.values, key: measure.key },
          generateChartFilters(dataFilters, dataHoverFilter),
          CATEGORICAL_COLORS,
          CHOROPLETH_COLORS
        );

        if (!didCancel) {
          setChartData({ data: data, type: type, isFetching: false });
        }
      } catch (error) {
        if (!didCancel) {
          setChartData({ data: null, isFetching: false });
          console.log(error);
        }
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, [dataFilters, dataHoverFilter, measure.key, measure.type, measure.values]);

  const [summaryChartData, setSummaryChartData] = useState({
    data: null,
    isFetching: true,
  });

  useEffect(() => {
    if (!measure.key) return;

    const fetchData = async () => {
      try {
        setSummaryChartData({ data: null, isFetching: true });

        const { data, type } = await fetchChartData(
          { type: measure.type, values: measure.values, key: measure.key },

          generateChartFilters(dataFilters, []),
          CATEGORICAL_COLORS,
          CHOROPLETH_COLORS
        );

        setSummaryChartData({ data, type, isFetching: false });
      } catch (error) {
        setSummaryChartData({ data: null, isFetching: false });
        console.log(error);
      }
    };

    fetchData();
  }, [measure.key, measure.type, measure.values, dataFilters]);

  return { chartData, summaryChartData };
}
