import bbox from '@turf/bbox';
import { WebMercatorViewport } from 'react-map-gl';
export default (feature, viewport) => {
  const [minLng, minLat, maxLng, maxLat] = bbox(feature);
  const newViewport = new WebMercatorViewport(viewport);
  const { longitude, latitude, zoom } = newViewport.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    {
      padding: 40,
    }
  );
  return { longitude, latitude, zoom };
};
