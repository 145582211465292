import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const TheTableHead = (props) => {
  const { data, measure } = props;

  const classes = useStyles();

  const numColummns = Math.max(
    ...data.map((d) => {
      return d.values.length;
    })
  );

  const tableColValues = data.find((d) => d.values.length === numColummns)
    .values;

  tableColValues.sort((a, b) => a.value - b.value);

  const cols = ['Name']
    .concat(
      tableColValues.map((d, index) => {
        return measure.type === 'list'
          ? measure.values[d.value - 1]
          : index === tableColValues.length - 1
          ? `${d.value}+`
          : `${d.value} - ${tableColValues[index + 1].value}`;
      })
    )
    .concat('Total');

  const RigthAlignedTableCell = withStyles(() => ({
    root: {
      textAlignLast: 'right',
    },
  }))(TableCell);

  return (
    <TableHead>
      <TableRow>
        {cols.map((col, index) => {
          if (index === 0) {
            return (
              <TableCell className={classes.tableCell} key={index}>
                {col}
              </TableCell>
            );
          } else {
            return (
              <RigthAlignedTableCell className={classes.tableCell} key={index}>
                {col}
              </RigthAlignedTableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default TheTableHead;
