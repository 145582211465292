const maxZooms = {
  departement: 8.5,
  commune: 9.5,
  section: 10.5,
  grid: 12.5,
};

export const noDataDepartement = {
  id: 'no-data-departement',
  source: 'haiti',
  'source-layer': 'departement',
  type: 'fill',
  paint: {
    'fill-opacity': 0.5,
    'fill-pattern': 'no-data-pattern',
  },
};

export const departement = {
  id: 'departement',
  source: 'haiti',
  'source-layer': 'departement',
  type: 'fill',
};

export const departementLine = {
  id: 'departement-line',
  source: 'haiti',
  'source-layer': 'departement',
  type: 'line',
  paint: {
    'line-color': '#000',
  },
};

export const noDataCommune = {
  id: 'no-data-commune',
  source: 'haiti',
  'source-layer': 'commune',
  type: 'fill',
  paint: {
    'fill-opacity': 0.5,
    'fill-pattern': 'no-data-pattern',
  },
};
export const commune = {
  id: 'commune',
  source: 'haiti',
  'source-layer': 'commune',
  type: 'fill',
};

export const communeLine = {
  id: 'commune-line',
  source: 'haiti',
  'source-layer': 'commune',
  type: 'line',
  paint: {
    'line-color': '#000',
  },
};

export const noDataSection = {
  id: 'no-data-section',
  source: 'haiti',
  'source-layer': 'commune',
  type: 'fill',
  paint: {
    'fill-opacity': 0.5,
    'fill-pattern': 'no-data-pattern',
  },
};

export const section = {
  id: 'section',
  source: 'haiti',
  'source-layer': 'section',
  type: 'fill',
};

export const sectionLine = {
  id: 'section-line',
  source: 'haiti',
  'source-layer': 'section',
  type: 'line',
  paint: {
    'line-color': '#000',
  },
};

export const grid = {
  id: 'grid',
  source: 'grid-source',
  type: 'circle',
};

export const point = {
  id: 'points',
  source: 'point-source',
  type: 'circle',
  minzoom: maxZooms.grid,
};
