import * as d3 from 'd3';

export const API = 'https://api.simast.axismaps.io/v1/data/';

export const TOKEN =
  'pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg';

export const ZOOM_LEVELS = [
  { name: 'departement' },
  { name: 'commune' },
  { name: 'section' },
  { name: 'grid' },
  { name: 'household' },
];

export const CATEGORICAL_COLORS = d3.schemeSet3.concat(d3.schemeSet1);
export const CHOROPLETH_COLORS = d3.schemeBlues[6].slice(1);

export const BASE_FILL_STYLE = {
  'fill-color': '#ccc',
  'fill-opacity': 0.3,
  'fill-outline-color': '#ccc',
};

export const BASE_GRID_STYLE = {
  'circle-radius': ['^', ['get', 'count'], 3 / 8],
  'circle-opacity': 0.75,
  'circle-stroke-color': '#999',
  'circle-stroke-width': 1,
};

export const BASE_POINT_STYLE = {
  'circle-radius': 5,
};

export const CHART_TYPES = {
  milieu: 'donut',
  // key: 'materiau_mur',
  // key: 'materiau_toit',
  // key: 'materiau_sol',
  // key: 'nombre_piece',
  lieu_aisance: 'horizontal',
  source_eclairage: 'horizontal',
  // key: 'evacuation_dechet',
  // key: 'energie_cuisson',
  // key: 'source_eau_potable',
  // key: 'source_eau_courant',
  distance_a_eau: 'funel',
  quantite_eau: 'funel',
  // key: 'activite1',
  // key: 'hdvi',
  hdvi_group: 'donut_%',
  nombre_membre: 'donut_%',
  nombre_homme: 'donut',
  nombre_femme: 'donut',
  // key: 'nombre_mam',
  // key: 'nombre_mas',
  enfant_5_ans: 'histogram',
  age_65_ans: 'histogram',
  // key: 'fcs',
  // key: 'hdds',
  // key: 'csi',
  n_handicap: 'histogram',
  n_chroniq: 'histogram',
  enfant_school: 'donut_%',
  analphabet: 'histogram',
  enfant_non_school: 'histogram',
  // key: 'pop_active',
  // key: 'pop_inactive',
  enfant_travailleur: 'histogram',
};
