import * as d3 from 'd3';

const BASE_POINT_STYLE = {
  'circle-radius': 5,
};

export default (measure, CHOROPLETH_COLORS, CATEGORICAL_COLORS) => {
  const scale = d3
    .scaleQuantize()
    .domain([measure.min, measure.max])
    .range(CHOROPLETH_COLORS)
    .nice();

  const breaks = scale.thresholds();

  if (measure.type !== 'list') {
    const fill = [
      'case',
      ['==', ['get', 'value'], 'null'],
      '#ccc',
      ['<', ['get', 'value'], breaks[0]],
      CHOROPLETH_COLORS[0],
      ['<', ['get', 'value'], breaks[1]],
      CHOROPLETH_COLORS[1],
      ['<', ['get', 'value'], breaks[2]],
      CHOROPLETH_COLORS[2],
      ['<', ['get', 'value'], breaks[3]],
      CHOROPLETH_COLORS[3],
      CHOROPLETH_COLORS[4],
    ];
    return {
      ...BASE_POINT_STYLE,
      'circle-color': fill,
      'circle-radius': 2,
    };
  } else {
    let fill = ['case', ['==', ['get', 'value'], null], '#ccc'];
    measure.values.forEach((value, index) => {
      if (index >= CATEGORICAL_COLORS.length) return; // for now just skip an excessive number of categories
      fill.push(['==', ['get', 'value'], index + 1], CATEGORICAL_COLORS[index]);
    });
    fill.push('#ccc');
    return {
      ...BASE_POINT_STYLE,
      'circle-color': fill,
      'circle-radius': 2,
    };
  }
};
