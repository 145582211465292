import React from 'react';

import ZoomSelect from '../InputSelect';
import { AppBar } from '@material-ui/core';

const Header = (props) => {
  const { activeLayer, onActiveLayerChange, interactiveLayerIds, zoom } = props;

  const style = {
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const geographieLevelOptions = interactiveLayerIds.map((d) => {
    if (d.name === 'household' && zoom < 13) {
      return { ...d, disabled: true };
    } else {
      return d;
    }
  });
  return (
    <AppBar position="sticky" style={style}>
      <h1>SIMAST</h1>
      <div style={{ color: 'white', display: 'flex' }}>
        <h4 style={{ paddingRight: 10 }}>Select Geographie Level: </h4>
        <ZoomSelect
          onChange={(value) => {
            onActiveLayerChange(value);
          }}
          label="name"
          item={activeLayer}
          items={geographieLevelOptions}
          // inputLabel="Geographie"
          inputLabelStyle={{ color: 'white' }}
          selectStyle={{
            width: 200,
            fontWeight: 500,
            color: 'white',
            fontSize: '18px',
          }}
        />
      </div>
    </AppBar>
  );
};

export default Header;
