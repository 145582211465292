import React, { createContext } from 'react';
import axios from 'axios';

import { useQuery } from 'react-query';

import { API } from '../lib/config.js';

export const TableContext = createContext();

const fetchTableData = async (key, activeLayerName, filters) => {
  const result = await axios.post(`${API}table/`, {
    field: key,
    unit: activeLayerName,
    filter: filters,
  });
  const json = result.data.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  return json;
};

export const ProvideTableData = (props) => {
  const {
    activeLayer,
    children,
    dataFilters,
    highlightedFeatureName,
    measure,
    onHoverFeature,
    selectedFeatureFilter,
  } = props;  

  let filters = [];
  if (dataFilters.length) filters = dataFilters;
  if (selectedFeatureFilter.length >= 3) filters.push(['=', `${selectedFeatureFilter[1]}.id`, selectedFeatureFilter[2]]);

  const { isLoading, data } = useQuery(
    [measure.key, activeLayer.name, filters],
    fetchTableData
  );

  const tableData = { data, isFetching: isLoading };

  const onHover = (row, index) => {
    try {
      const hoveredFeatureId = tableData.data.find(
        (feature) => feature.name === row[0]
      ).id;
      onHoverFeature(hoveredFeatureId);
    } catch (error) {}
  };
  return (
    <TableContext.Provider
      value={[tableData, measure, highlightedFeatureName, onHover]}
    >
      {children}
    </TableContext.Provider>
  );
};
