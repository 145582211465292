import React from 'react';
import TheSidebarFilter from './TheSidebarTabsFilter.js';
import TheChart from '../theChart/TheChart.js';

import { makeStyles } from '@material-ui/core/styles';
import InputSelect from './../InputSelect';

import { useChartData } from '../../providers/provideChartData';
const useStyles = makeStyles({
  measures: {
    maxHeight: '100%',
    padding: '20px 20px',
  },
  inputSelect: {},
});

const Sidebar = (props) => {
  const {
    onMeasureSelect,
    measure,
    measures,
    sidebarFilters,
    onSidebarFiltersChange,
  } = props;
  const classes = useStyles();

  const [_, summaryChartData] = useChartData();
  const chartData = summaryChartData;

  const renderInputSelect = (onChange, measure, measures) => {
    if (!measures || !measure) return;
    return (
      <InputSelect
        onChange={(value) => {
          onChange(value);
        }}
        label="label"
        item={measure}
        className={classes.inputSelect}
        items={measures}
        inputLabel="Select a measure:"
        selectStyle={{
          width: 320,
          fontWeight: 500,
          color: '#2D3748',
          fontSize: '18px',
        }}
      />
    );
  };

  return (
    <div
      style={{ height: 'calc(100vh - 60px)', maxHeight: 'calc(100vh - 60px)' }}
    >
      <div className={classes.measures}>
        {renderInputSelect(onMeasureSelect, measure, measures)}
        <div style={{ minWidth: 200, marginTop: 20 }}>
          <TheChart
            data={chartData.data}
            type={chartData.type}
            isLoading={chartData.isFetching}
          />
        </div>
        <div>
          {sidebarFilters.map((f) => (
            <TheSidebarFilter
              key={f.name}
              name={f.name}
              labels={f.labels}
              onChange={onSidebarFiltersChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
