import React, { useState, useEffect, useReducer } from 'react';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import TheHeader from './components/theHeader/TheHeader.js';
import TheSidebar from './components/theSidebar/TheSidebar.js';
import TheTable from './components/theTable/TheTable.js';
import AtlasPopup from './components/AtlasPopup.js';
import AtlasLoadingIndicator from './components/AtlasLoadingIndicator.js';
import AtlasLabels from './components/AtlasLabels.js';

import {
  formatHoverObject,
  generateBreadcrumb,
  generateChoroplethStyle,
  generateEmptyLayerFilter,
  generateGridStyle,
  generatePointStyle,
} from './lib/helpers/index.js';

import { makeStyles } from '@material-ui/core/styles';
import {
  API,
  ZOOM_LEVELS,
  CATEGORICAL_COLORS,
  CHOROPLETH_COLORS,
  BASE_GRID_STYLE,
  BASE_FILL_STYLE,
  BASE_POINT_STYLE,
} from './lib/config.js';

import { ProvideTableData } from './providers/provideTableData.js';
import { ProvideChartData } from './providers/provideChartData.js';
import { ProvideMap } from './providers/provideMap.js';

import Atlas from './components/Atlas.js';

const initialState = {
  activeLayer: ZOOM_LEVELS[0],
  atlas: null,
  breadcrumbItems: [],
  dataFilters: [],
  dataHoverFilter: [],
  hoveredFeature: null,
  hoveredFeatureInfo: null,
  hoverFilter: ['in', 'id', ''],
  interactiveLayerIds: ['departement'],
  selectedFeature: null,
  selectedFeatureFilter: ['has', 'id'],
  layerVisibility: {
    departement: 'visible',
    commune: 'none',
    section: 'none',
    grid: 'none',
  },
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'MAP-RESET': {
      return initialState;
    }
    case 'SET-ATLAS': {
      return {
        ...state,
        atlas: payload,
      };
    }
    case 'SELECT-FEATURE': {
      const selectedFeature = payload;

      const itemIndex = ZOOM_LEVELS.findIndex(
        (item) => item.name === selectedFeature.layer.id
      );

      const nextIndex = itemIndex + 1;
      const nextZoomLevel = ZOOM_LEVELS[nextIndex];

      return {
        ...state,
        dataFilters: [
          [
            '=',
            `${selectedFeature.layer.id}.id`,
            selectedFeature.properties.id,
          ],
        ],
        selectedFeatureFilter: selectedFeature
          ? ['in', selectedFeature.layer.id, selectedFeature.properties.id]
          : ['has', 'id'],
        activeLayer: nextZoomLevel,
        interactiveLayerIds: [nextZoomLevel.name],
        layerVisibility: {
          departement: 'none',
          commune: 'none',
          section: 'none',
          grid: 'none',
          [nextZoomLevel.name]: 'visible',
        },
        selectedFeature,
        breadcrumbItems: generateBreadcrumb(
          state.breadcrumbItems,
          selectedFeature
        ),
      };
    }
    case 'SET-ZOOM-LEVEL': {
      const zoomLevel = payload;
      const { breadcrumbItems } = state;

      const findFeatureInBreadcrumByLayer = (breadcrumbItems, layerName) => {
        const breadcrumbItemThatContainsFeature = breadcrumbItems.find(
          (item) => {
            return item.featureLayer === layerName;
          }
        );

        return breadcrumbItemThatContainsFeature
          ? breadcrumbItemThatContainsFeature.selectedFeature
          : null;
      };

      const feature = findFeatureInBreadcrumByLayer(
        breadcrumbItems,
        zoomLevel.name
      );

      return {
        ...state,
        selectedFeature: feature,
        activeLayer: zoomLevel,
        dataFilters: feature
          ? [['=', `${feature.layer.id}.id`, feature.properties.id]]
          : state.dataFilters,
        selectedFeatureFilter: feature
          ? ['in', feature.layer.id, feature.properties.id]
          : state.selectedFeatureFilter,
        interactiveLayerIds: [zoomLevel.name],
        layerVisibility: {
          departement: 'none',
          commune: 'none',
          section: 'none',
          grid: 'none',
          [zoomLevel.name]: 'visible',
        },
      };
    }
    case 'SET-MOUSE-POSITION': {
      return {
        ...state,
        mousePosition: payload,
      };
    }
    case 'SET-HOVERED-MAP-FEATURE': {
      const { feature, info } = payload;
      const { activeLayer } = state;

      if (
        state.hoveredFeature &&
        state.hoveredFeature.properties.id === feature.properties.id
      )
        return state;

      return {
        ...state,
        dataHoverFilter: ['=', `${activeLayer.name}.id`, feature.properties.id],
        hoveredFeature: feature,
        hoveredFeatureInfo: info,
        hoverFilter: ['in', 'id', feature.properties.id],
      };
    }
    case 'RESET-HOVERED-MAP-FEATURE': {
      return {
        ...state,
        hoveredFeature: null,
        hoveredFeatureInfo: null,
        hoverFilter: ['in', 'id', ''],
      };
    }
    case 'HIGHLIGHT-MAP-FEATURE-BY-ID': {
      const hoveredFeatureId = payload;
      return {
        ...state,
        hoverFilter: ['in', 'id', hoveredFeatureId],
      };
    }
    default:
      return state;
  }
}

function App() {
  const [
    {
      dataHoverFilter,
      activeLayer,
      hoverFilter,
      breadcrumbItems,
      hoveredFeature,
      hoveredFeatureInfo,
      mousePosition,
      interactiveLayerIds,
      layerVisibility,
      selectedFeature,
      selectedFeatureFilter,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const classes = useStyles();

  //################
  // Set Initial State
  //################
  const [viewport, setViewport] = useState({
    zoom: 7,
  });

  //################
  // Set Grid Size by Zoom Level
  //################

  const [gridSize, setGridSize] = useState(0.1);
  useEffect(() => {
    const roundZoom = Math.round(viewport.zoom);
    switch (roundZoom) {
      case 7:
        setGridSize(0.25);
        break;
      case 9:
        setGridSize(0.08);
        break;
      case 11:
        setGridSize(0.01);
        break;
      case 13:
        setGridSize(0.0025);
        break;
      default:
        break;
    }
  }, [viewport.zoom]);

  //################
  // Set Map Bounds
  //################
  const [mapBounds, setMapBounds] = useState(null);

  const [dataFilters, setDataFilters] = useState([]);

  //################
  // Set Measures Data
  //################

  const [measures, setMeasures] = useState({ data: null, isFetching: true });
  const [measure, setMeasure] = useState({
    key: '',
    label: '',
    type: '',
    values: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setMeasures({ data: null, isFetching: true });
        const { data } = await axios.get(`${API}fields/`);
        setMeasures({ data, isFetching: false });
        setMeasure(data[10]);
      } catch (error) {
        console.log(error);
        setMeasures({ data: null, isFetching: false });
      }
    };

    fetchData();
  }, []);

  //################
  // Set GeographicData Data
  //################

  const [geographicData, setGeographicData] = useState({
    data: null,
    isFetching: false,
  });
  useEffect(() => {
    if (!measure.key) return;
    const fetchData = async () => {
      try {
        setGeographicData({ data: null, isFetching: true });
        const result = await axios.post(`${API}query/`, {
          field: measure.key,
          filter: dataFilters,
        });
        const json = result.data;
        let indexedData = {};
        Object.values(json).forEach((level) => {
          level.forEach((d) => {
            indexedData[d.id] = d;
          });
        });

        setGeographicData({ data: indexedData, isFetching: false });
      } catch (error) {
        setGeographicData({ data: null, isFetching: false });
        console.log(error);
      }
    };

    fetchData();
  }, [measure.key, dataFilters]);

  //################
  // Set Choropleth Fill Style
  //################

  const [choroplethStyle, setChoroplethStyle] = useState(BASE_FILL_STYLE);
  useEffect(() => {
    if (geographicData.isFetching || !geographicData.data) return;

    setChoroplethStyle(
      generateChoroplethStyle(
        geographicData.data,
        { type: measure.type, values: measure.values },
        CHOROPLETH_COLORS,
        CATEGORICAL_COLORS
      )
    );
  }, [geographicData, measure.type, measure.values]);

  //################
  // Set No Data Filter Fill Style
  //################

  const [noDataLayerFilterStyle, setNoDataLayerFilterStyle] = useState([
    'has',
    'id',
  ]);
  useEffect(() => {
    if (geographicData.isFetching || !geographicData.data) return;

    setNoDataLayerFilterStyle(generateEmptyLayerFilter(geographicData.data));
  }, [geographicData]);

  //################
  // Set Grid Data
  //################

  const [gridData, setGridData] = useState({ data: null, isFetching: false });
  useEffect(() => {
    const fetchData = async () => {
      setGridData({ data: null, isFetching: true });
      try {
        const { data } = await axios.post(`${API}grid/`, {
          field: measure.key,
          size: gridSize,
          filter: dataFilters,
        });
        data.features.sort((a, b) => b.properties.count - a.properties.count);
        setGridData({ data, isFetching: false });
      } catch (error) {
        setGridData({ data: null, isFetching: false });
        console.log(error);
      }
    };

    if (activeLayer.name === 'grid') fetchData();
  }, [measure.key, activeLayer.name, gridSize, dataFilters]);

  //################
  // Set Grid Style
  //################

  const [gridStyle, setGridStyle] = useState(BASE_GRID_STYLE);
  useEffect(() => {
    if (gridData.isFetching || !gridData.data) return;
    setGridStyle(
      generateGridStyle(gridData.data, {
        type: measure.type,
        values: measure.values,
      })
    );
  }, [gridData.data, gridData.isFetching, measure.type, measure.values]);

  //################
  // Set Point Data
  //################

  const [pointData, setPointData] = useState({ data: null, isFetching: false });
  useEffect(() => {
    if (activeLayer.name !== 'household' || !mapBounds) return;
    const fetchData = async () => {
      try {
        setPointData({ data: null, isFetching: true });

        let pointDataFilters = mapBounds;
        if (dataFilters) {
          pointDataFilters = dataFilters
            .reduce((accumulator, currentValue) => {
              accumulator.concat(currentValue.values);
              return accumulator;
            }, [])
            .concat(mapBounds);
        }

        const { data } = await axios.post(`${API}points/`, {
          field: measure.key,
          filter: dataFilters
            ? pointDataFilters.concat(dataFilters)
            : pointDataFilters,
        });
        setPointData({ data, isFetching: false });
      } catch (error) {
        setPointData({ data: null, isFetching: false });
        console.log(error);
      }
    };

    fetchData();
  }, [measure.key, mapBounds, activeLayer.name, dataFilters]);

  //################
  // Set Point Style
  //################

  const [pointStyle, setPointStyle] = useState(BASE_POINT_STYLE);
  useEffect(() => {
    if (pointData.isFetching || !pointData.data) return;

    setPointStyle(
      generatePointStyle(
        {
          min: measure.min,
          max: measure.max,
          type: measure.type,
          values: measure.values,
        },
        CHOROPLETH_COLORS,
        CATEGORICAL_COLORS
      )
    );
  }, [
    pointData.data,
    pointData.isFetching,
    measure.min,
    measure.max,
    measure.type,
    measure.values,
  ]);

  //################
  //Hover Logic
  //################

  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(seconds + 1);
    }, 100);
    return () => clearInterval(timer);
  });

  const handleOnMapHover = (event) => {
    const { features, lngLat } = event;
    const feature = features ? features[0] : null;

    dispatch({ type: 'SET-MOUSE-POSITION', payload: lngLat });

    if (feature) {
      if (seconds > 10) {
        setSeconds(0);
        dispatch({
          type: 'SET-HOVERED-MAP-FEATURE',
          payload: formatHoverObject(
            feature,
            activeLayer,
            geographicData,
            measure
          ),
        });
      }
    } else {
      dispatch({ type: 'RESET-HOVERED-MAP-FEATURE' });
    }
  };

  const renderPopup = () => {
    if (hoveredFeature && hoveredFeatureInfo && mousePosition) {
      return (
        <ProvideChartData
          measure={measure}
          dataFilters={dataFilters}
          dataHoverFilter={dataHoverFilter}
        >
          <AtlasPopup
            longitude={mousePosition[0]}
            latitude={mousePosition[1]}
            tile={hoveredFeature.properties.name}
            description={measure.label}
            info={hoveredFeatureInfo}
            displayChart={hoveredFeature.layer.id !== 'grid'}
          />
        </ProvideChartData>
      );
    }
    return null;
  };

  //################
  // Atlas Loading Logic
  //################

  const [isAtlasLoading, setIsAtlasLoading] = useState(false);
  useEffect(() => {
    if (
      geographicData.isFetching ||
      gridData.isFetching ||
      pointData.isFetching
    ) {
      setIsAtlasLoading(true);
    } else {
      setIsAtlasLoading(false);
    }
  }, [geographicData.isFetching, gridData.isFetching, pointData.isFetching]);

  //################
  // Set Filters Logic
  //################
  const [sidebarFilters, setSidebarFilters] = useState([
    {
      name: 'Vulnerability group',
      labels: [
        { name: 'Plus Vulnérable', active: false },
        { name: 'Mi-Vulnérable', active: false },
        { name: 'Moins Vulnérable', active: false },
        { name: 'Non Vulnérable', active: false },
      ],
    },
    {
      name: 'Milieu de résidence',
      labels: [
        { name: 'Aires Metropolitaines', active: false },
        { name: 'Aires Urbaines', active: false },
        { name: 'Aires Rurales', active: false },
      ],
    },
  ]);

  const handleOnSidebarFiltersChange = (e) => {
    const _items = [...sidebarFilters];
    const list = _items.map((item) => {
      if (item.name === e.name) {
        return {
          name: e.name,
          labels: e.list,
        };
      } else {
        return { ...item };
      }
    });

    setSidebarFilters([...list]);

    let newFilters = dataFilters ? [...dataFilters] : [];
    list.forEach((l) => {
      const measure = measures.data.find((m) => m.label === l.name);
      const { key, values } = measure;
      newFilters = newFilters.filter((f) => f[1] !== key);
      l.labels.forEach((f) => {
        if (f.active) {
          const index = values.indexOf(f.name) + 1;
          newFilters.push(['=', key, index]);
        }
      });
    });

    setDataFilters(newFilters);
  };

  //render
  return (
    <div className="App">
      <TheHeader
        activeLayer={activeLayer}
        onActiveLayerChange={(zoomLevel) =>
          dispatch({ type: 'SET-ZOOM-LEVEL', payload: zoomLevel })
        }
        interactiveLayerIds={[...ZOOM_LEVELS]}
        zoom={viewport.zoom}
      />
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <ProvideChartData
            measure={measure}
            dataFilters={dataFilters}
            dataHoverFilter={dataHoverFilter}
          >
            <TheSidebar
              sidebarFilters={sidebarFilters}
              onSidebarFiltersChange={handleOnSidebarFiltersChange}
              measures={measures.data}
              measure={measure}
              onMeasureSelect={(value) => {
                setMeasure(value);
              }}
            />
          </ProvideChartData>
        </div>

        <div className={classes.atlas}>
          <AtlasLoadingIndicator isAtlasLoading={isAtlasLoading} />
          <ProvideChartData
            measure={measure}
            dataFilters={dataFilters}
            dataHoverFilter={dataHoverFilter}
          >
            <AtlasLabels
              className={classes.atlasLabels}
              title={measure.label}
            />
          </ProvideChartData>

          <ProvideMap>
            <Atlas
              noDataLayerFilterStyle={noDataLayerFilterStyle}
              breadcrumbItems={breadcrumbItems}
              choroplethStyle={choroplethStyle}
              gridData={gridData}
              gridStyle={gridStyle}
              hoverFilter={hoverFilter}
              interactiveLayerIds={interactiveLayerIds}
              layerVisibility={layerVisibility}
              onHover={handleOnMapHover}
              onLoadAtlas={({ target: map }) => {
                dispatch({ type: 'SET-ATLAS', payload: map });
              }}
              onMapBoundsChange={(mapBounds) => setMapBounds(mapBounds)}
              onMapReset={() => dispatch({ type: 'MAP-RESET' })}
              selectedFeature={selectedFeature}
              onSelectFeature={(feature) =>
                dispatch({ type: 'SELECT-FEATURE', payload: feature })
              }
              onViewportChange={(newViewport) => setViewport(newViewport)}
              pointData={pointData}
              pointStyle={pointStyle}
              renderPopup={renderPopup}
              selectedFeatureFilter={selectedFeatureFilter}
            />
          </ProvideMap>
        </div>

        <div className={classes.table}>
          <ProvideTableData
            measure={measure}
            activeLayer={activeLayer}
            dataFilters={dataFilters}
            onHoverFeature={(featureId) =>
              dispatch({
                type: 'HIGHLIGHT-MAP-FEATURE-BY-ID',
                payload: featureId,
              })
            }
            selectedFeatureFilter={selectedFeatureFilter}
            highlightedFeatureName={
              hoveredFeature && hoveredFeature.properties
                ? hoveredFeature.properties.name
                : null
            }
          >
            <TheTable className={classes.table} />
          </ProvideTableData>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '360px minmax(0, 1fr) ',
    gridTemplateRows: 'minmax(0, 1fr) minmax(0, 0.4fr)',
    maxHeight: 'calc(100vh - 60px)',
    height: 'calc(100vh - 60px)',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    overflow: 'hidden',
  },
  sidebar: {
    gridArea: '1 / 1 / 3 / 2',
    height: 'calc(100vh - 60px)',
    overflow: 'auto',
  },
  atlas: {
    gridArea: '1 / 2 / 2 / 3',
    position: 'relative',
  },
  table: {
    gridArea: '2 / 2 / 3 / 3',
    maxHeight: '100%',
  },
  zoomSelect: {
    position: 'absolute',
    left: '370px',
    top: '80px',
    zIndex: '10',
  },
  atlasLabels: {
    position: 'absolute',
    zIndex: 2,
    right: 10,
    marginTop: 10,
    borderRadius: 4,
    width: 180,
    padding: 10,
    backgroundColor: 'white',
    boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
  },
});

export default App;
