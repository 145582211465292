import React, { useState, useContext, createContext } from 'react';
import noDataImage from './../assets/nodata.png';
import { LinearInterpolator } from 'react-map-gl';

import { getFeatureFitBounds } from './../lib/helpers/index.js';

export const MapContext = createContext();

const HAITI_FIT_BOUNDS = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-74.432373046875, 17.983957957423037],
        [-71.5869140625, 17.983957957423037],
        [-71.5869140625, 20.174567450431844],
        [-74.432373046875, 20.174567450431844],
        [-74.432373046875, 17.983957957423037],
      ],
    ],
  },
};

const INITIAL_STATE = {
  height: 400,
  width: 400,
  latitude: 18.9894,
  longitude: -72.9382,
  zoom: 7,
};

export function ProvideMap(props) {
  const [viewport, setViewport] = useState(INITIAL_STATE);

  const [map, setMap] = useState(null);

  const onLoad = ({ target: map }) => {
    setMap(map);

    setViewport((viewport) => {
      return {
        ...viewport,
        ...getFeatureFitBounds(HAITI_FIT_BOUNDS, viewport),
        transitionInterpolator: new LinearInterpolator({}),
        transitionDuration: 500,
      };
    });
    const noDataImg = new Image();
    noDataImg.src = noDataImage;
    noDataImg.onload = () => {
      map.addImage('no-data-pattern', noDataImg);
    };
  };

  return (
    <MapContext.Provider
      value={{ viewport, setViewport, onLoad, map }}
      {...props}
    />
  );
}

export const useMap = () => {
  return useContext(MapContext);
};

export const { Consumer: MapConsumer } = MapContext;

export const withMap = (Component) => (props) => {
  return (
    <MapConsumer>{(value) => <Component map={value} {...props} />}</MapConsumer>
  );
};
