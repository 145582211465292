import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const InputSelect = (props) => {
  const {
    onChange,
    items,
    className,
    inputLabel,
    inputLabelStyle,
    item,
    label,
    selectStyle,
  } = props;
  const classes = useStyles();

  if (!items) return null;
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const MenuItems = items.map((item, index) => {
    return item.disabled ? (
      <MenuItem
        style={{ textTransform: 'capitalize' }}
        key={index}
        value={item}
        disabled
      >
        {item[label]}
      </MenuItem>
    ) : (
      <MenuItem
        style={{ textTransform: 'capitalize' }}
        key={index}
        value={item}
      >
        {item[label]}
      </MenuItem>
    );
  });
  return (
    <div className={className}>
      <FormControl className={classes.formControl}>
        <InputLabel style={inputLabelStyle} id="select-outlined-label">
          {inputLabel}
        </InputLabel>
        <Select
          labelId="select-outlined-label"
          id="select-outlined"
          value={item}
          onChange={handleChange}
          label="item"
          style={{ ...selectStyle, textTransform: 'capitalize' }}
        >
          {MenuItems}
        </Select>
      </FormControl>
    </div>
  );
};
InputSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputLabel: PropTypes.string,
  className: PropTypes.string,
  inputLabelStyle: PropTypes.object,
  item: PropTypes.object,
  label: PropTypes.string,
  selectStyle: PropTypes.object,
};

export default InputSelect;
