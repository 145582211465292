export default (dataFilters, dataHoverFilter) => {
  if (dataFilters.length === 0 && dataHoverFilter.length === 0) {
    return null;
  } else if (dataFilters.length === 0) {
    return [dataHoverFilter];
  } else if (dataHoverFilter.length === 0) {
    return dataFilters;
  } else {
    return [...dataFilters].concat([dataHoverFilter]);
  }
};
