import React, { useEffect } from 'react';
import ReactMapGL, { LinearInterpolator, Source, Layer } from 'react-map-gl';
import ReactResizeDetector from 'react-resize-detector';

import { useMap } from '../providers/provideMap';

import AtlasControls from './../components/AtlasControls';
import AtlasBreadcrumb from './../components/AtlasBreadcrumb.js';

import { TOKEN } from './../lib/config.js';

import {
  departement,
  departementLine,
  commune,
  communeLine,
  section,
  sectionLine,
  grid,
  point,
  noDataCommune,
  noDataSection,
  noDataDepartement,
} from './../lib/interactive-layers.js';

import { getFeatureFitBounds } from './../lib/helpers/index.js';

export default function Atlas(props) {
  const {
    breadcrumbItems,
    choroplethStyle,
    gridData,
    gridStyle,
    hoverFilter,
    interactiveLayerIds,
    layerVisibility,
    noDataLayerFilterStyle,
    onHover,
    onLoadAtlas,
    onMapBoundsChange,
    onMapReset,
    onSelectFeature,
    onViewportChange,
    pointData,
    pointStyle,
    renderPopup,
    selectedFeature,
    selectedFeatureFilter,
  } = props;

  const { viewport, setViewport, onLoad, map } = useMap();

  const onResize = (width, height) => {
    setViewport((viewport) => ({
      ...viewport,
      width,
      height,
    }));
  };

  const handleOnload = (e) => {
    onLoad(e);
    onLoadAtlas(e);
  };

  const handleClick = (e) => {
    const { features } = e;
    const feature = features.length ? features[0] : null;

    if (!feature) return;

    onSelectFeature(feature);
  };

  useEffect(() => {
    if (!selectedFeature) return;
    setViewport((viewport) => {
      return {
        ...viewport,
        ...getFeatureFitBounds(selectedFeature, viewport),
        transitionInterpolator: new LinearInterpolator({}),
        transitionDuration: 500,
      };
    });
  }, [selectedFeature]);

  const handleMapReset = () => {
    setViewport((viewport) => ({
      ...viewport,
      latitude: 18.9894,
      longitude: -72.9382,
      zoom: 7,
      transitionInterpolator: new LinearInterpolator({}),
      transitionDuration: 500,
    }));

    onMapReset();
  };

  const handleSelectBreadcrumbFeature = (feature) => {
    onSelectFeature(feature);
    setViewport((viewport) => {
      return {
        ...viewport,
        ...getFeatureFitBounds(feature, viewport),
        transitionInterpolator: new LinearInterpolator({}),
        transitionDuration: 500,
      };
    });
  };

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport);
    onViewportChange(newViewport);
  };

  const handleOnMouseUp = (_) => {
    if (!map) return;

    const boundsArray = [];
    const bounds = map.getBounds().toArray();
    boundsArray.push(['>=', 'longitude', bounds[0][0]]);
    boundsArray.push(['>=', 'latitude', bounds[0][1]]);
    boundsArray.push(['<=', 'longitude', bounds[1][0]]);
    boundsArray.push(['<=', 'latitude', bounds[1][1]]);

    onMapBoundsChange(boundsArray);
  };

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
      <div style={{ width: '100%', height: '100%' }}>
        <AtlasBreadcrumb
          items={breadcrumbItems}
          onSelectFeature={handleSelectBreadcrumbFeature}
          onBackToCountry={handleMapReset}
        />
        <ReactMapGL
          doubleClickZoom={false}
          interactiveLayerIds={interactiveLayerIds}
          mapboxApiAccessToken={TOKEN}
          mapOptions={{
            maxBounds: [-76.458, 17.03, -60.624, 20.915],
            maxZoom: 15,
          }}
          mapStyle="mapbox://styles/mapbox/light-v10"
          onClick={handleClick}
          onHover={onHover}
          onLoad={handleOnload}
          onMouseUp={handleOnMouseUp}
          onViewportChange={handleViewportChange}
          {...viewport}
        >
          <AtlasControls onMapReset={handleMapReset} />

          <Source id="haiti" type="vector" url="mapbox://axismaps.ciifdnvd">
            <Layer
              {...noDataDepartement}
              layout={{ visibility: layerVisibility.departement }}
              filter={noDataLayerFilterStyle}
            />
            <Layer
              {...noDataCommune}
              layout={{ visibility: layerVisibility.commune }}
              filter={noDataLayerFilterStyle}
            />
            <Layer
              {...noDataSection}
              layout={{ visibility: layerVisibility.section }}
              filter={noDataLayerFilterStyle}
            />
            <Layer
              {...departement}
              paint={choroplethStyle}
              filter={selectedFeatureFilter}
              layout={{ visibility: layerVisibility.departement }}
            />
            <Layer {...departementLine} filter={hoverFilter} />
            <Layer
              beforeId="departement"
              {...commune}
              paint={choroplethStyle}
              filter={selectedFeatureFilter}
              layout={{ visibility: layerVisibility.commune }}
            />
            <Layer
              beforeId="departement"
              {...communeLine}
              filter={hoverFilter}
            />
            <Layer
              beforeId="commune"
              {...section}
              paint={choroplethStyle}
              filter={selectedFeatureFilter}
              layout={{ visibility: layerVisibility.section }}
            />
            <Layer beforeId="commune" {...sectionLine} filter={hoverFilter} />
          </Source>
          <Source id="grid-source" type="geojson" data={gridData.data}>
            <Layer
              {...grid}
              paint={gridStyle}
              layout={{ visibility: layerVisibility.grid }}
            />
          </Source>
          <Source id="point-source" type="geojson" data={pointData.data}>
            <Layer {...point} paint={pointStyle} />
          </Source>
          {renderPopup()}
        </ReactMapGL>
      </div>
    </ReactResizeDetector>
  );
}
