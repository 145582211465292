import React from 'react';
import { NavigationControl } from 'react-map-gl';

export default (props) => {
  const { onMapReset } = props;
  return (
    <div
      key="mainMapControls"
      style={{ position: 'absolute', left: 10, top: 10, zIndex: 1 }}
    >
      <NavigationControl showCompass={false} />
      <div
        className="mapboxgl-ctrl mapboxgl-ctrl-group"
        style={{ marginTop: 10 }}
      >
        <button
          className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-reset"
          onClick={onMapReset}
        >
          ↩︎
        </button>
      </div>
    </div>
  );
};
